import { makeStyles } from '@material-ui/core/styles';
import appleImage from '../../images/app-store-badge.svg';
import googleImage from '../../images/google-play-badge.svg';

const useStyles = makeStyles({
    a: {
        display: 'inline-block',
        width: 135,
        height: 40,
        cursor: 'pointer',
    },
});

const iosLink = 'https://apps.apple.com/us/app/voluntime-hour-logs/id1567853199';
const androidLink = 'https://play.google.com/store/apps/details?id=com.mittaldev.voluntime';

const AppStoreBadge = props => {
    const classes = useStyles();

    return (
        <a href={props.platform === 'ios' ? iosLink : androidLink} target='_blank' rel='noopener noreferrer' className={classes.a}>
            <img src={props.platform === 'ios' ? appleImage : googleImage} alt={props.platform === 'ios' ? 'App Store' : 'Google Play Store'} />
        </a>
    );
};

export default AppStoreBadge;