import { useRef, useState, useEffect, memo } from 'react';
import { Container, Typography, Grid, Paper, Hidden } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Alert } from '@material-ui/lab';
import { useLocation } from 'react-router-dom';
import Login from './Login';
import SignUp from './SignUp';
import Partners from './Partners';
import Product from './Product';
import About from './About';

const useStyles = makeStyles(theme => ({
    landingContainer: {
        minHeight: '97vh',
        paddingBottom: '4vh',
        boxSizing: 'border-box',
    },
    productContainer: {
        paddingRight: '5%',
    },
    loginPaper: {
        backgroundColor: '#F0F0F0',
        textAlign: 'center',
        paddingTop: '10%',
        boxSizing: 'border-box',
        paddingLeft: '5%',
        paddingRight: '5%',
        width: '90%',
        [theme.breakpoints.down('sm')]: {
            width: '100%',
        },
    },
    partners: {
        maxWidth: 800,
        textAlign: 'center',
    },
    about: {
        height: '100vh',
        textAlign: 'left',
    },
    bold: {
        fontWeight: 'bold'
    },
    banner: {

    },
}));

const Landing = props => {
    const location = useLocation();

    const classes = useStyles();

    const [view, setView] = useState('login');

    const aboutRef = useRef(null);
    const signUpFieldRef = useRef(null);

    const focusSignUpField = () => signUpFieldRef.current.focus({ preventScroll: true });

    const scrollToAbout = () => aboutRef.current.scrollIntoView({ behavior: 'smooth' });

    const aboutSignUpClicked = () => {
        if (view !== 'signup') {
            setTimeout(focusSignUpField, 100);
            setView('signup');
        } else {
            focusSignUpField();
        }
        document.body.scrollIntoView({ behavior: 'smooth' });
    };

    useEffect(() => window.scrollTo(0, 0), []);

    return (
        <>
            <Alert severity='info' className={classes.banner}>The Voluntime mobile app has launched! Search "Voluntime" on the App Store or Google Play Store<Hidden mdUp>.</Hidden><Hidden smDown> and download it today!</Hidden></Alert>
            <Container>
                <Grid container justifyContent='center' className={classes.landingContainer}>
                    <Grid container item alignItems='stretch' justifyContent='center' className={classes.productContainer} md={6} sm={9} xs={12}>
                        <Product scrollToAbout={scrollToAbout} />
                    </Grid>
                    <Hidden xsDown>
                        <Grid container item alignItems='stretch' justifyContent='center' md={6} sm={9} xs={12}>
                            <Paper className={classes.loginPaper} variant='outlined'>
                                {
                                    view === 'login'
                                        ? <Login from={location.state?.from} setUser={props.setUser} setView={setView} />
                                        : <SignUp from={location.state?.from} user={props.user} setUser={props.setUser} fieldRef={signUpFieldRef} setView={setView} />
                                }
                            </Paper>
                        </Grid>
                    </Hidden>
                </Grid>
                <Grid container alignItems='center' justifyContent='center'>
                    <Grid item xs={9}>
                        <Typography variant='h3' className={classes.bold}>
                            Our Users
                        </Typography>
                        <Typography variant='subtitle1'>
                            When you create your organization, you won't be alone.
                        </Typography><br />
                    </Grid>
                    <Grid item xs={12} sm={9}>
                        <Partners />
                    </Grid>
                </Grid>
                <Grid ref={aboutRef} container className={classes.about} alignItems='center' justifyContent='center' spacing={4}>
                    <About aboutSignUpClicked={aboutSignUpClicked} />
                </Grid>
            </Container>
        </>
    );
};

export default memo(Landing);