import { useMediaQuery } from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Carousel, { slidesToShowPlugin } from '@brainhubeu/react-carousel';
import '@brainhubeu/react-carousel/lib/style.css';
import Enginera from '../../images/partners/enginera.svg';
import Hosa from '../../images/partners/hosa.png';
import Issdc from '../../images/partners/issdc.png';
import DreamYoung from '../../images/partners/dreamyoung.jpeg';
import Surfmate from '../../images/partners/surfmate.png';
import YouthInMedicine from '../../images/partners/youthinmedicine.png';
import Youth4SciTech from '../../images/partners/youth4scitech.png';
import CuraProject from '../../images/partners/curaproject.png';
import ScienceHolic from '../../images/partners/scienceholic.jpeg';

const useStyles = makeStyles({
    bold: {
        fontWeight: 'bold'
    },
    carousel: {
        width: '100%',
        maxWidth: 1200,
    },
    image: {
        height: 'auto',
        width: '95%',
        maxWidth: 300,
    }
});

const Partners = () => {

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    const classes = useStyles({ isMobile });
    return (
        <>

            <Carousel
                plugins={[
                    'centered',
                    'arrows',
                    'infinite',
                    'fastSwipe',
                    {
                        resolve: slidesToShowPlugin,
                        options: {
                            numberOfSlides: isMobile ? 1 : 2
                        }
                    },
                ]}
                lazyLoad
                className={classes.carousel}
            >
                <img src={Enginera} className={classes.image} loading='lazy' />
                <img src={DreamYoung} className={classes.image} loading='lazy' />
                <img src={Surfmate} className={classes.image} loading='lazy' />
                <img src={YouthInMedicine} className={classes.image} loading='lazy' />
                <img src={Issdc} className={classes.image} loading='lazy' />
                <img src={Youth4SciTech} className={classes.image} loading='lazy' />
                <img src={ScienceHolic} className={classes.image} loading='lazy' />
                <img src={CuraProject} className={classes.image} loading='lazy' />
                <img src={Hosa} className={classes.image} loading='lazy' />
            </Carousel>
        </>
    );
};

export default Partners;